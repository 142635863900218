<template>
  <div class="h-screen flex justify-center items-center">
    <div class="mx-auto self-center">
      <vs-card>
        <div class="vx-row">
          <div class="vx-col lg:w-1/5 mt-3">
            <imageLazy
              :imageStyle="{ 'border-radius': '15px' }"
              :imageClass="'doctorImage'"
              :src="baseURL + doctor.ProfileImagePath"
              placeHolderType="doctor"
            />
          </div>
          <div class="vx-col lg:w-1/3 mt-2">
            <h2>{{ $t("Dr") }}. {{ doctor.Name }}</h2>
          </div>
        </div>
        <div class="ml-10 mr-10">
          <v-date-picker
            :locale="$vs.rtl ? 'ar' : 'en'"
            :min="minDate"
            style="width: 95%"
            v-if="showAppointments"
            v-model="appointmentDate"
            @input="getAppointmentSessions"
            @update:from-page="pageChange"
            :attributes="attrs"
            :min-date="minDate"
          />

          <h5 v-if="showAppointments" class="mt-2">
            {{ $t("SelectATimeFor") }} {{ appointmentDateStrig }} :
          </h5>
          <div v-if="showAppointments" class="vx-row">
            <div
              v-for="item in sessions"
              :key="item.name"
              class="lg:w-1/4 mt-2 ml-2 mr-2"
            >
              <div
                v-on:click="
                  selectSession(item);
                  isSelected = !isSelected;
                "
              >
                <vx-card
                  style="height: 80%"
                  :style="[
                    !item.IsAvailable
                      ? { 'background-color': 'gray' }
                      : item.ID == Session.ID && isSelected
                      ? { 'background-color': '#004477', 'font-color': 'white' }
                      : item.IsBlocked
                      ? { 'background-color': 'red', 'font-color': 'white' }
                      : {},
                  ]"
                >
                  <p
                    :style="[
                      item.ID == Session.ID && isSelected
                        ? { color: 'white' }
                        : item.IsBlocked
                        ? { color: 'white' }
                        : !item.IsAvailable
                        ? { color: 'white' }
                        : {},
                    ]"
                    style="margin-bottom: 3px"
                    class="text-center fontBook"
                  >
                    {{ item.DateFrom.slice(11, 16) }} -
                    {{ item.DateTo.slice(11, 16) }}
                  </p>
                </vx-card>
              </div>
            </div>
            <p
              style="font-size: 16px"
              v-if="
                sessions.length > 0 &&
                !(sessions.findIndex((element) => element.IsAvailable == true) >= 0)
              "
            >
              {{ $t("onlineAppointmentSpecificDateMessage") }}
              <u style="color: blue">
                <a
                  :href="
                    'mailto:patient.service@Daaweny.com' +
                    '?subject=' +
                    'Dr:' +
                    doctor.Name +
                    ',Date:' +
                    changeFormatedDate(this.appointmentDate)
                  "
                  >patient.service@Daaweny.com</a
                >
              </u>
            </p>
          </div>

          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="mt-8 mb-8 flex flex-wrap vx-row items-center justify-end">
                <u
                  @click.stop="$emit('closePop')"
                  style="color: #004477; font-weight: bold; font-size: 15px"
                  >{{ $t("Back") }}</u
                >
                <vs-button class="ml-4 mt-2" :disabled="!isSelected" @click="Proceed()"
                  >{{ $t("Save") }}
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </vs-card>
    </div>
  </div>
</template>
<script>
import { domain } from "@/gloabelConstant.js";
import moment from "moment";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {
    imageLazy,
  },

  data() {
    return {
      isSelected: false,
      baseURL: domain,
      sessions: [],
      Session: {},
      showBiography: false,
      showAppointments: true,
      minDate: Date.now(),
      appointmentDate: new Date(),
      appointmentDateStrig: "",
      monthSessions: [],
      doctor: this.editSessionModel.Doctor,
    };
  },
  computed: {
    attrs() {
      debugger;
      return [
        {
          bar: "green",
          dates: this.monthSessions.map((ob) => ob.DateFrom),
        },
      ];
    },
    getAppointment() {
      return this.appointmentDate;
    },
  },
  props: {
    editSessionModel: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    pageChange(obj) {
      debugger;
      this.sessions = [];
      debugger;
      var search = {
        DateFrom: obj.year + "/" + obj.month + "/1",
        To: new Date(obj.year, obj.month, 0),
      };
      search.To.setDate(search.To.getDate() + 1);
      search.DateTo = moment(search.To).format("LL");
      search.doctorID = this.doctor.ID;

      this.$store.dispatch("DoctorList/SearchDoctorMonthSessions", search).then((res) => {
        this.monthSessions = res.data.Data;
      });
    },
    selectSession(session) {
      this.Session = session;
    },
    changeFormatedDate() {
      return moment(this.appointmentDate).format("LL");
    },
    addHours(date, h) {
      if (typeof date === "string") date = new Date(date);
      date.setTime(date.getTime() + h * 60 * 60 * 1000);
      return date;
    },
    getAppointmentSessions() {
      debugger;

      // var dateNow = new Date();
      // this.appointmentDate = this.addHours(this.appointmentDate, 1);
      // if (dateNow >= this.appointmentDate) {
      //   window.PastDate();
      //   this.this.appointmentDate = new Date();
      // }
      // else
      {
        debugger;
        this.appointmentDateStrig = moment(this.appointmentDate).format("LL");
        var searchAppointment = {};
        searchAppointment.DoctorID = this.doctor.ID;

        this.appointmentDate = new Date(this.appointmentDate.setHours(0, 0, 0, 0));
        this.appointmentDate = this.addHours(this.appointmentDate, 21);

        searchAppointment.DayUTC = this.appointmentDate;
        this.sessions = [];
        this.$vs.loading();
        this.$store
          .dispatch("DoctorList/SearchDoctorSessions", searchAppointment)
          .then((res) => {
            this.sessions = res.data.Data;
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
    viewBiography() {
      this.showBiography = !this.showBiography;
    },
    viewAppointment() {
      debugger;
      this.showAppointments = !this.showAppointments;
    },
    Proceed() {
      debugger;
      //viewType 2 add new session,1 edit reserved session
      if (this.editSessionModel.viewType == 2) {
        this.Session.DoctorName = this.doctor.Name;
        this.$emit("SetNewSession", this.Session);
      } else {
        this.editSessionModel.DoctorSessionID = this.Session.ID;
        this.editSessionModel.ID = this.editSessionModel.PatientReservationSessionID;
        this.$vs.loading();
        this.$store
          .dispatch("patientList/UpdatePatientReservationSession", this.editSessionModel)
          .then((res) => {
            if (res.status == 200) {
              debugger;
              this.$vs.loading.close();
              window.showAddSuccess();
              this.$emit("closePop");
            }
          })
          .catch((err) => {
            window.showError(err.response.data.message);
            this.$vs.loading.close();
          });
      }
    },
    cancelSession() {
      debugger;
      this.$store.dispatch("patientList/CancelSession", this.data.ID).then((res) => {
        if (res.status == 200) {
          window.showSuccess();
        }
      });
      this.$emit("closePop");
    },
  },
  created() {
    debugger;
    this.appointmentDate = this.editSessionModel.DoctorSession.DateFrom;
    this.getAppointmentSessions();
  },
  mounted() {},
};
</script>
<style>
.container {
  background: transparent
    radial-gradient(closest-side at 50% 50%, #004477 0%, #004477 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px;
  opacity: 1;
  text-align: center;
  width: 70%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.container {
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  display: inline-block;
  padding: 0.8rem;
  margin: 1%;
}
.TextLogo {
  letter-spacing: 0px;
  font-size: 2rem;
  font-weight: bold;
  color: #004477;
}
.P-Text {
  color: #ffffff;
  text-align: center !important    ;
  font-size: 1.2rem;
  letter-spacing: 0px;
  opacity: 1;
  margin-left: 2rem;
}
</style>
